import CONSTANT from '../../constant/index';
import axiosApiInstance from '../../utils/api';
import AuthUtils from '../../utils/auth';
import { transformObjtoQuery } from '../../utils/helpers';
import { emailRegex, phoneRegex } from '../../utils/regex';
import asyncLocalStorage from '../../utils/storage';

// @Todo Checking Patient Data
const checking = async (value) => {
  const query = transformObjtoQuery({
    ...(value.match(emailRegex)
      ? {
          email: value,
        }
      : {}),
    ...(value.match(phoneRegex)
      ? {
          phone: value,
        }
      : {}),
  });

  // @todo this only valid for hitting unprotected API
  const clinicId = JSON.parse(localStorage.getItem('clinic'));
  const config = {
    headers: {
      database_name: `${clinicId.data.dbName}` || '',
    },
  };

  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${CONSTANT.USERS_API_URL}check-patient${query}`,
    config,
  );

  const dataExistingPatient = {
    id_customer: response.data.data.profile[0].id_customer,
    full_name: response.data.data.profile[0].full_name,
    first_name: response.data.data.profile[0].middle_name,
    middle_name: response.data.data.profile[0].middle_name,
    last_name: response.data.data.profile[0].last_name,
    gender: response.data.data.profile[0].gender,
    phone: response.data.data.profile[0].phone,
    email: response.data.data.profile[0].email,
    birth_date: response.data.data.profile[0].birth_date,
    birth_place: response.data.data.profile[0].birth_place,
    address: response.data.data.profile[0].address,
    updated_at: response.data.data.profile[0].updated_at,
  };

  // save the response for next step
  if (response.data.data.profile.length > 0) {
    localStorage.setItem(`${clinicId.data.instance_id}_patient`, JSON.stringify(dataExistingPatient));
  }

  return response.data;
};

// @Todo Register user
const register = async (userData) => {
  const phoneNumber = /^([1-9][0-9]*)|([0]+)$/.test(userData.phone);

  const clinic = await asyncLocalStorage.getItem('clinic').then((res) => JSON.parse(res));

  if (!clinic?.data?.instance_id) {
    throw Error('Failed to register, please refresh your page');
  }

  const config = {
    headers: {
      client_id: clinic.data.instance_id,
    },
  };

  const bodyRes = {
    email: userData.email,
    password: userData.password,
    repeat_password: userData.repeat_password,
    firstName: userData.firstName,
    lastName: userData.lastName,
    phone: phoneNumber ? `0${userData.phone}` : `${userData.phone}`,
  };

  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}${CONSTANT.USERS_API_URL}register`,
    bodyRes,
    config,
  );

  return response.data;
};

// @Todo Re-Register user
const reRegister = async (userData) => {
  const clinicId = JSON.parse(localStorage.getItem('clinic'));

  const config = {
    headers: {
      client_id: clinicId.data.instance_id,
    },
  };

  const bodyRes = {
    pos_user_id: userData.idCustomer,
    email: userData.new_email || userData.email,
    password: userData.password,
    repeat_password: userData.repeat_password,
    firstName: userData.firstName,
    lastName: userData.lastName,
    phone: userData.phone,
    middleName: userData.middleName,
  };

  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}${CONSTANT.USERS_API_URL}register`,
    bodyRes,
    config,
  );

  return response.data;
};

// @Todo Login user
const login = async (userData) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}${CONSTANT.USERS_API_URL}login`,
    userData,
  );

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }

  return response.data;
};

const checkExistingPatient = async (userData) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}${CONSTANT.USERS_API_URL}check-existing-patient`,
    userData,
  );

  return response.data;
};

const logout = () => {
  AuthUtils.logout();
};

const authService = {
  checking,
  register,
  reRegister,
  login,
  logout,
  checkExistingPatient,
};

export default authService;
