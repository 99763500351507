import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { HiOutlineMenu, HiOutlineShoppingCart } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useKeypress from '../../customHook/useKeyPress';
import { getCartCounter } from '../../features/cart/cartSlice';
import BackButton from '../molecules/BackButton';
import SideDrawer from './SideDrawer';

function SecondaryHeader({
  title,
  back,
  search,
  searchActive,
  onSearch,
  onSearchCallback,
  onCancelSearch,
  showCart = true,
  isUrl,
}) {
  /* cek only if url is e-appointment */

  const history = useHistory();
  const { counter } = useSelector((state) => state.cart);
  const [leftNavigationOpen, setLeftNavigationOpen] = useState(false);

  const toggleLeftNavigation = () => {
    setLeftNavigationOpen((prevState) => !prevState);
  };

  const dispatch = useDispatch();
  useKeypress('Escape', () => {
    if (leftNavigationOpen) {
      setLeftNavigationOpen(false);
    }
  });
  useEffect(() => {
    dispatch(getCartCounter());
  }, [dispatch]);
  return (
    <>
      {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' && window.location.pathname === '/e-appointment' ? (
        <header
          className={`z-[100] fixed max-w-screen-sm mx-auto left-0 right-0 top-0 w-full flex items-center bg-white-gray h-14 justify-between border-b border-solid border-[#f4f4f4] ${
            searchActive ? '' : 'px-6'
          }`}
        >
          <div>
            <BackButton
              onBack={() => {
                history.push('/landing');
              }}
            />
          </div>
          <div>
            <button
              className="my-first-step relative bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white"
              onClick={toggleLeftNavigation}
              type="button"
            >
              <HiOutlineMenu />
            </button>
          </div>
        </header>
      ) : (
        <header
          className={`z-[100] bg-light fixed max-w-screen-sm mx-auto left-0 right-0 top-0 w-full flex items-center justify-between bg-bg-white-gray h-14 border-b border-solid border-[#f4f4f4] ${
            searchActive ? '' : 'px-6'
          }`}
        >
          {process.env.REACT_APP_ENABLE_ECOMMERCE === 'no' && window.location.pathname === '/e-appointment' ? (
            <button
              className="my-first-step relative bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white"
              onClick={toggleLeftNavigation}
              type="button"
            >
              <HiOutlineMenu />
            </button>
          ) : null}
          {searchActive ? (
            <div
              className="w-full px-3 flex"
              style={{
                marginTop: '17px',
              }}
            >
              <input
                placeholder="Cari Produk disini"
                className="w-full border-x-0 border-t-0 ring-0 outline-none focus:ring-0 border-[#D8D8D8]"
                type="text"
                onChange={onSearchCallback}
              />
              <IoMdClose
                size={25}
                style={{ alignSelf: 'center' }}
                className="cursor-pointer"
                onClick={() => {
                  onCancelSearch();
                }}
              />
            </div>
          ) : (
            <>
              {back && (
                <button
                  type="button"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    if (isUrl) {
                      history.replace(isUrl);
                    } else {
                      history.go(-1);
                    }
                    // console.log(isUrl);
                    // history.go(-1);
                  }}
                  className="absolute left-6 top-0 bottom-0 m-auto w-5 h-5 text-xl text-[#454547E5]"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 8.83223C18 8.27994 17.5523 7.83223 17 7.83223H5.88313C5.03426 7.83223 4.57145 6.84127 5.11632 6.19035L8.37863 2.2931C8.68955 1.92166 8.68955 1.38079 8.37863 1.00935L8.30053 0.916048C7.90083 0.438553 7.16662 0.438553 6.76692 0.916049L0.537303 8.35812C0.226378 8.72956 0.226379 9.27044 0.537304 9.64188L6.76692 17.084C7.16662 17.5614 7.90083 17.5614 8.30053 17.084L8.37863 16.9907C8.68955 16.6192 8.68955 16.0783 8.37863 15.7069L5.11632 11.8096C4.57145 11.1587 5.03425 10.1678 5.88313 10.1678H17C17.5523 10.1678 18 9.72005 18 9.16777V8.83223Z"
                      fill="#1d1d1f"
                      fillOpacity="0.9"
                    />
                  </svg>
                </button>
              )}
              <h6 className="w-full m-0 font-body text-xl font-semibold text-black-gray text-left tracking-wide pl-10">
                {title}
              </h6>
              {/* add cart over  here */}
              <div className="flex gap-2">
                <div className="relative">
                  {showCart && process.env.REACT_APP_ENABLE_ECOMMERCE === true && (
                    <>
                      <HiOutlineShoppingCart
                        className="cursor-pointer"
                        onClick={() => {
                          history.push('/cart');
                        }}
                        size="25"
                      />
                      {counter?.data !== 0 && (
                        <span
                          style={{
                            top: '-7px',
                            right: '-5px',
                          }}
                          className="absolute rounded bg-[#FF7272] w-4 h-4 block text-center text-xs text-white tracking-tight"
                        >
                          {counter?.data}
                        </span>
                      )}
                    </>
                  )}
                </div>
                {search && (
                  <button
                    type="button"
                    onClick={() => {
                      onSearch();
                    }}
                    className="m-auto w-5 h-5 text-xl text-[#454547E5]"
                  >
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.6643 16.8288C12.8972 16.8288 16.3286 13.3969 16.3286 9.1634C16.3286 4.92987 12.8972 1.49805 8.6643 1.49805C4.43135 1.49805 1 4.92987 1 9.1634C1 13.3969 4.43135 16.8288 8.6643 16.8288Z"
                        fill="white"
                        stroke="#454547"
                        strokeOpacity="0.9"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.2148 6.1634C10.8803 5.82795 10.4827 5.56192 10.045 5.38062C9.60736 5.19931 9.13816 5.1063 8.66442 5.10694C7.66806 5.10694 6.76638 5.5105 6.11401 6.1634H11.2148ZM14.1746 14.676L18 18.5019L14.1746 14.676Z"
                        fill="white"
                      />
                      <path
                        d="M14.1746 14.676L18 18.5019M11.2148 6.1634C10.8803 5.82795 10.4827 5.56192 10.045 5.38062C9.60736 5.19931 9.13816 5.1063 8.66442 5.10694C7.66806 5.10694 6.76638 5.5105 6.11401 6.1634H11.2148Z"
                        stroke="#454547"
                        strokeOpacity="0.9"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </>
          )}
        </header>
      )}
      <SideDrawer isOpen={leftNavigationOpen} isOnClose={toggleLeftNavigation} />
    </>
  );
}

SecondaryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  back: PropTypes.bool.isRequired,
  search: PropTypes.oneOfType([PropTypes.bool]),
};

SecondaryHeader.defaultProps = {
  search: false,
};

export default React.memo(SecondaryHeader);
