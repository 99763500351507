// Import the functions you need from the SDKs you need
/* import { getAnalytics } from 'firebase/analytics'; */
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDLv0e1W19h_wapStQNiZyuv_eYU6relK8',
  authDomain: 'e-appointment-49a4d.firebaseapp.com',
  databaseURL: 'https://e-appointment-49a4d-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'e-appointment-49a4d',
  storageBucket: 'e-appointment-49a4d.appspot.com',
  messagingSenderId: '1034469125712',
  appId: '1:1034469125712:web:cc6eee75edc5e7f40c6b55',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/* const analytics = getAnalytics(app); */
const messaging = getMessaging(app);
export const database = getDatabase(app);

export const fetchToken = (setTokenFound) =>
  getToken(messaging, {
    vapidKey: 'BB1NObs1IGOhla-9ZVqffT_qrzV1EOPVwOVOs2rz7cdx9kxj3l4JECxPPDqbCmfeg3sgbloMUp_pEj9jjzrg2M8',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
