/* eslint-disable import/no-unresolved */
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

const CarouselCategoryWrapper = styled((props) => <Swiper {...props} />)`
  display: flex;
  flex-direction: column-reverse;
  .swiper-pagination {
    position: relative;
    bottom: 0;
    display: inline-flex;
    justify-content: center;
    background-color: #c4c4c4;
    width: auto;
    max-width: max-content;
    margin-top: 1.25rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    overflow: hidden;
    .swiper-pagination-bullet {
      margin: 0;
      width: 14px;
      height: 8px;
      border-radius: 0.25rem;
      background-color: transparent;
      &.swiper-pagination-bullet-active {
        background-color: #a77bab;
      }
    }
  }
`;

function CarouselCategory({ children, isNavigation, isPagination, slidesPerView = 2, spaceBetween = 16 }) {
  const pagination = {
    clickable: true,
  };

  return (
    <CarouselCategoryWrapper
      className="overflow-visible"
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      navigation={isNavigation}
      pagination={isPagination ? pagination : ''}
    >
      {children}
    </CarouselCategoryWrapper>
  );
}

CarouselCategory.propTypes = {
  children: PropTypes.node.isRequired,
  isNavigation: PropTypes.oneOfType([PropTypes.bool]),
  isPagination: PropTypes.oneOfType([PropTypes.bool]),
};

CarouselCategory.defaultProps = {
  isNavigation: false,
  isPagination: false,
};

export default React.memo(CarouselCategory);
