import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import NeosoftLogo from '../../assets/neosoft_dark.svg';
import { getCartCounter } from '../../features/cart/cartSlice';
import { countNotification } from '../../features/notification/notificationSlice';
import BottomBar from './BottomBar';
import Header from './Header';
import SecondaryHeader from './SecondaryHeader';

const containerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.5,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.4,
      damping: 8,
      staggerChildren: 0.4,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
  },
};

function Layout({
  searchActive,
  onSearch,
  onSearchCallback,
  onCancelSearch,
  children,
  isHeader,
  isTitle,
  isUrl,
  isBack,
  isSearch,
  header,
  menu,
  footer,
  showCart,
  useMotion = true,
}) {
  const history = useHistory();
  const location = useLocation();
  const queryFrom = new URLSearchParams(location.search).get('from');
  const containerClass = 'relative w-full bg-white-gray items-center justify-center overflow-hidden';

  const { counter } = useSelector((state) => state.cart);
  const stateNotification = useSelector((state) => state.notification);
  const VERSION = '1.0.9';
  // const VERSION = 'for Training';

  const dispatch = useDispatch();
  useEffect(() => {
    if (header) {
      dispatch(getCartCounter());
      dispatch(countNotification());
    }
  }, [dispatch, header]);

  useEffect(() => {
    console.log(history);
  }, [history]);
  return (
    <motion.main
      className={cx({
        'pb-24': menu,
        'pt-14': isHeader,
      })}
      {...(useMotion
        ? {
            variants: containerVariants,
            initial: 'hidden',
            animate: 'visible',
            exit: 'exit',
          }
        : {})}
    >
      {isHeader && (
        <SecondaryHeader
          onCancelSearch={onCancelSearch}
          searchActive={searchActive}
          onSearch={onSearch}
          onSearchCallback={onSearchCallback}
          title={isTitle}
          isUrl={queryFrom || isUrl}
          back={isBack}
          search={isSearch}
          showCart={showCart}
        />
      )}
      {header && (
        <Header isSearch={isSearch} cartCounter={counter?.data} notificationCounter={stateNotification?.count?.data} />
      )}
      <div
        className={cx(`${containerClass} min-h-screen `, {
          'min-h-[calc(100vh_-_56px)]': isHeader,
        })}
      >
        {children}
      </div>
      {menu && <BottomBar />}
      {footer && (
        <div className="absolute bg-white-gray gap-y-1 h-16 flex flex-col justify-censter items-center z-10 bottom-0 left-0 right-0 m-auto">
          <a
            href="https://neosoft.co.id/"
            className="flex items-center justify-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <em className="w-full font-body text-sm font-medium text-secondary mr-0.5 mt-1 block">Powered by</em>
            <LazyLoadImage src={NeosoftLogo} className="w-24 block" alt="neosoft logo" />
          </a>
          <small>Build Version {VERSION}</small>
        </div>
      )}
    </motion.main>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHeader: PropTypes.oneOfType([PropTypes.bool]),
  isTitle: PropTypes.oneOfType([PropTypes.string]),
  isUrl: PropTypes.oneOfType([PropTypes.string]),
  isBack: PropTypes.oneOfType([PropTypes.bool]),
  isSearch: PropTypes.oneOfType([PropTypes.bool]),
  header: PropTypes.oneOfType([PropTypes.bool]),
  menu: PropTypes.oneOfType([PropTypes.bool]),
  footer: PropTypes.oneOfType([PropTypes.bool]),
};

Layout.defaultProps = {
  isHeader: false,
  isTitle: '',
  isUrl: '/',
  isBack: true,
  isSearch: false,
  header: true,
  menu: false,
  footer: false,
};

export default React.memo(Layout);
