/* eslint-disable import/no-unresolved */
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import PropTypes from 'prop-types';
import React from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

function CarouselBanner({ children }) {
  return (
    <Swiper
      className="overflow-visible mySwiper"
      modules={[Autoplay, A11y, Pagination]}
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loopAdditionalSlides={3}
      centeredSlides
      spaceBetween={16}
      slidesPerView={1}
      pagination
    >
      {children}
    </Swiper>
  );
}

CarouselBanner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(CarouselBanner);
