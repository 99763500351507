import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isUndefined, set } from 'lodash';

import cartService from './cartService';

const initialState = {
  totalPrice: {
    data: 0,
    error: false,
    loading: false,
  },
  counter: {
    data: 0,
    error: false,
    loading: false,
  },
  cart: {
    data: [],
    error: false,
    loading: false,
    loadingMore: false,
    message: '',
    pagination: {},
  },
  delete: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  update: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  productDirectBuy: [],
};

export const getCart = createAsyncThunk('cart/get', async (arg, thunkAPI) => {
  try {
    const getCartAction = await cartService.get(arg);
    if (arg?.callbackSuccess) {
      arg.callbackSuccess(getCartAction);
    }
    return getCartAction;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getTotalPrice = createAsyncThunk('cart/get-total-price', async (arg, thunkAPI) => {
  try {
    const getTotalPriceAction = await cartService.getTotalPrice(arg);
    if (arg?.callbackSuccess) {
      arg.callbackSuccess(getTotalPriceAction);
    }
    return getTotalPriceAction;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getCartCounter = createAsyncThunk('cart/get-counter', async (arg, thunkAPI) => {
  try {
    return await cartService.getCounter(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteCart = createAsyncThunk('cart/delete', async (arg, thunkAPI) => {
  try {
    return await cartService.deleteCart(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCart = createAsyncThunk('cart/update', async (arg, thunkAPI) => {
  try {
    return await cartService.update(arg.id, arg.payload);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const createCart = createAsyncThunk('cart/create', async (arg, thunkAPI) => {
  try {
    const save = await cartService.create(arg);
    arg.callbackSuccess();
    return save;
  } catch (error) {
    arg.callbackError(error?.response?.data?.message);
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    saveProductForDirectBuy: (state, action) => {
      set('state', 'productDirectBuy', action.payload);
    },
    setActivity: (state, action) => {
      console.log('adaloh');
      const { index, data } = action.payload;
      set(state, `cart.data[${index}].activity`, data);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state, action) => {
        if (isUndefined(action.meta.arg)) {
          set(state, 'cart.loading', true);
        } else {
          set(state, 'cart.loadingMore', true);
        }
      })
      .addCase(getCart.fulfilled, (state, action) => {
        set(state, 'cart.loading', false);
        set(state, 'cart.loadingMore', false);
        set(
          state,
          'cart.data',
          action.payload.meta?.current_page === 1
            ? action.payload.results
            : [...(state.cart.data || []), ...action.payload.results],
        );
        set(state, 'cart.pagination', action.payload.meta);
      })
      .addCase(getCart.rejected, (state) => {
        set(state, 'cart.loading', false);
        set(state, 'cart.loadingMore', false);
        set(state, 'cart.error', true);
      })
      .addCase(createCart.pending, (state) => {
        set(state, 'create.loading', true);
      })
      .addCase(createCart.fulfilled, (state, action) => {
        set(state, 'create.loading', false);
        set(state, 'create.data', action.payload);
      })
      .addCase(createCart.rejected, (state) => {
        set(state, 'create.loading', false);
        set(state, 'create.error', true);
      })
      .addCase(deleteCart.pending, (state) => {
        set(state, 'delete.loading', true);
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        set(state, 'delete.loading', false);
        set(state, 'delete.data', action.payload);
      })
      .addCase(deleteCart.rejected, (state) => {
        set(state, 'delete.loading', false);
        set(state, 'delete.error', true);
      })
      .addCase(updateCart.pending, (state) => {
        set(state, 'update.loading', true);
      })
      .addCase(updateCart.fulfilled, (state, action) => {
        set(state, 'update.loading', false);
        set(state, 'update.data', action.payload);
      })
      .addCase(updateCart.rejected, (state) => {
        set(state, 'update.loading', false);
        set(state, 'update.error', true);
      })
      .addCase(getTotalPrice.pending, (state) => {
        set(state, 'totalPrice.loading', true);
      })
      .addCase(getTotalPrice.fulfilled, (state, action) => {
        set(state, 'totalPrice.loading', false);
        set(state, 'totalPrice.data', action.payload);
      })
      .addCase(getTotalPrice.rejected, (state) => {
        set(state, 'totalPrice.loading', false);
        set(state, 'totalPrice.error', true);
      })
      .addCase(getCartCounter.pending, (state) => {
        set(state, 'counter.loading', true);
      })
      .addCase(getCartCounter.fulfilled, (state, action) => {
        set(state, 'counter.loading', false);
        set(state, 'counter.data', action.payload);
      })
      .addCase(getCartCounter.rejected, (state) => {
        set(state, 'counter.loading', false);
        set(state, 'counter.error', true);
      });
  },
});

export const { setActivity, saveProductForDirectBuy } = cartSlice.actions;
export default cartSlice.reducer;
