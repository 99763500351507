import { Alert, Image, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Drawer from 'react-modern-drawer';
import { useSelector } from 'react-redux';

import CONSTANT from '../../constant';
import Quantity from '../atoms/Quantity';
import { checkAvailibilityBogof, checkAvailibilityDiscount } from './ItemProduct';

function ItemCart({
  id,
  // eslint-disable-next-line no-unused-vars
  isDiscount,
  onDelete,
  productName,
  // eslint-disable-next-line no-unused-vars
  productCode,
  productDescription,
  productPrice,
  // eslint-disable-next-line no-unused-vars
  productImage,
  images,
  productQty,
  onAdd,
  onMinus,
  // eslint-disable-next-line no-unused-vars
  outlet,
  enableQuantity = true,
  textWarning = 'Remove Item',
  // eslint-disable-next-line no-unused-vars
  marketingActivities = [],
  onDiscounted,
}) {
  // const getOutletName = outlet?.map((valueOutlet) => valueOutlet?.outlet?.outlet_name);
  const stateActivity = useSelector((state) => state.activity);
  const [isOpen, setIsOpen] = useState(false);
  const [activity, setActivity] = useState(null);
  const [bogof, setBogof] = useState(null);
  const [qty, setQty] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [freeProduct, setFreeProduct] = useState(null);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const deleteCart = (payload) => {
    onDelete(payload);
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const data = {
      product_treatment_code: productCode,
      price_ecommerce: productPrice,
    };

    const activities = marketingActivities.length > 0 ? marketingActivities : stateActivity.activity.data;
    const discount = checkAvailibilityDiscount(data, activities);
    setActivity(discount);

    if (onDiscounted) {
      const totalDiscount = 100;
      console.log(discount);
      onDiscounted(totalDiscount);
    }
  }, [productCode, productName, productPrice, productQty, qty, stateActivity, marketingActivities]);

  useEffect(() => {
    const data = {
      product_treatment_code: productCode,
      price_ecommerce: productPrice,
    };
    const activities = marketingActivities.length > 0 ? marketingActivities : stateActivity.activity.data;
    const discountBogof = checkAvailibilityBogof(data, qty, activities);
    setBogof(discountBogof);
    console.log({ discountBogof });
  }, [productCode, productName, productPrice, qty, stateActivity, marketingActivities]);

  useEffect(() => {
    setQty(productQty);
  }, []);

  useEffect(() => {
    if (bogof) {
      if (activity?.bogof?.free) {
        if (activity?.bogof?.free?.length === 1) {
          setFreeProduct(activity?.bogof?.free[0]);
        } else {
          setFreeProduct(null);
        }
      }
    }
  }, [activity, bogof]);

  useEffect(() => {
    setFreeProduct(null);
    setActivity(null);
    setBogof(null);
  }, [qty]);

  return (
    <>
      <div className="relative block rounded-md bg-light border mb-4 w-full gap-4 cart-item">
        <div className="flex">
          <span className="block p-3 pr-0">
            {/* <Image */}
            {/*  width={150} */}
            {/*  preview={false} */}
            {/*  src={`${productImage}` || CONSTANT.DEFAULT_FALLBACK_IMAGE} */}
            {/*  fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE} */}
            {/*  className="w-full block mx-auto" */}
            {/*  alt={productDescription} */}
            {/* /> */}
            {images?.length > 0 ? (
              <Image
                width={150}
                preview={false}
                src={`${images[0]}` || CONSTANT.DEFAULT_FALLBACK_IMAGE}
                fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE}
                className="w-full block mx-auto"
                alt={productCode}
              />
            ) : (
              <Image
                width={150}
                preview={false}
                src={CONSTANT.DEFAULT_FALLBACK_IMAGE}
                fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE}
                className="w-full block mx-auto"
                alt={productCode}
              />
            )}
          </span>
          <div className="relative p-3">
            <h3 className="font-display text-base font-medium text-dark/90">{productName}</h3>
            <p className="hidden font-body text-xs font-normal text-dark/60 pr-8">{productDescription}</p>
            {/* {getOutletName && <div className="font-medium">Outlet: {getOutletName.join(', ')}</div>} */}
          </div>
        </div>
        {bogof ? (
          <div className="p-3">
            <Alert type="info" description={bogof} />
          </div>
        ) : null}
        <div className="relative p-3 flex justify-between items-center border-t border-solid border-dark/20">
          <p className="font-body font-normal text-sm text-dark/90">
            <span className="font-bold">
              <CurrencyFormat
                value={activity ? activity.price_discount : productPrice || 0}
                displayType="text"
                decimalSeparator=","
                thousandSeparator="."
                prefix="Rp "
              />
            </span>
            {activity && activity?.discount_value > 0 && (
              <span className="inline-flex items-center ml-1">
                <del className="text-xs text-[#E25C5C]/90">
                  <CurrencyFormat
                    value={productPrice || 0}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="Rp "
                  />
                </del>
                <em className="bg-danger/20 px-1 py-0.5 not-italic text-xs text-danger font-bold rounded ml-2">
                  {activity.discount_value}%
                </em>
              </span>
            )}
          </p>
          <div className="flex">
            <button type="button" onClick={() => toggleDrawer()} className="mr-3">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 7.5V4.5C9 4.23478 9.10536 3.98043 9.29289 3.79289C9.48043 3.60536 9.73478 3.5 10 3.5H14C14.2652 3.5 14.5196 3.60536 14.7071 3.79289C14.8946 3.98043 15 4.23478 15 4.5V7.5M4 7.5H20H4ZM10 11.5V17.5V11.5ZM14 11.5V17.5V11.5ZM5 7.5L6 19.5C6 20.0304 6.21071 20.5391 6.58579 20.9142C6.96086 21.2893 7.46957 21.5 8 21.5H16C16.5304 21.5 17.0391 21.2893 17.4142 20.9142C17.7893 20.5391 18 20.0304 18 19.5L19 7.5H5Z"
                  stroke="#454547"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {enableQuantity && (
              <Quantity
                onAdd={(pQty) => {
                  onAdd(pQty);
                  setQty(pQty);
                }}
                onMinus={(pQty) => {
                  onMinus(pQty);
                  setQty(pQty);
                }}
                amount={productQty}
              />
            )}
          </div>
        </div>
        {activity && activity?.bogof && bogof ? (
          <div className="flex flex-row p-3 border-t">
            {!freeProduct ? (
              <Select className="w-full" placeholder="Choose free item" size="middle">
                {activity?.bogof?.free?.map((bogofProduct) => (
                  <Select.Option key={bogofProduct.item_id}>{bogofProduct.product_name}</Select.Option>
                ))}
              </Select>
            ) : (
              <Alert
                type="success"
                className="w-full"
                description={
                  <>
                    Free product <strong>{freeProduct?.product_name}</strong>
                  </>
                }
              />
            )}
          </div>
        ) : null}
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
        zIndex={100000}
      >
        <div className="w-full relative pt-6 px-6">
          <h4 className="font-display font-semibold text-dark text-center text-xl mb-2">{textWarning}</h4>
          <p className="font-body font-normal text-center text-dark/50 mt-0 mb-4">Are you sure to remove this item ?</p>
        </div>
        <div className="flex pt-2 px-6 pb-2">
          <button
            type="button"
            className="w-full tracking-wide block px-5 py-3.5 bg-primary/20 hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 font-display text-primary hover:text-white focus:text-white active:text-white font-normal text-base leading-tight rounded-[.625rem] focus:outline-none focus:ring-0 transition duration-150 ease-in-out mr-2"
            onClick={() => toggleDrawer()}
          >
            No
          </button>
          <button
            type="button"
            className="w-full tracking-wide block px-5 py-3.5 bg-primary hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 font-display text-white hover:text-white focus:text-white active:text-white font-normal text-base leading-tight rounded-[.625rem] focus:outline-none focus:ring-0 transition duration-150 ease-in-out mr-2"
            onClick={() => deleteCart(id)}
          >
            <span>Yes</span>
          </button>
        </div>
      </Drawer>
    </>
  );
}

ItemCart.propTypes = {
  isDiscount: PropTypes.bool.isRequired,
};

export default React.memo(ItemCart);
