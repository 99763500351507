import React from 'react';
import { HiChevronRight } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

function ItemProductSeeAll({ href }) {
  const history = useHistory();
  return (
    <button
      type="button"
      onClick={() => {
        history.push(href);
      }}
      className="flex gap-3 flex-col items-center justify-center bg-white w-full border rounded-lg overflow-hidden my-2 min-h-[223px]"
    >
      <div className="h-7 w-7 border rounded-full flex items-center justify-center">
        <HiChevronRight className="text-2xl fill-primary" />
      </div>
      <div className="text-center text-primary font-semibold">See all other products</div>
    </button>
  );
}

export default React.memo(ItemProductSeeAll);
