import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const API_URL = '/api/v1/';

const user = JSON.parse(localStorage.getItem('user')) || null;
const clinic = JSON.parse(localStorage.getItem('clinic')) || null;

// @Todo Create An Appointment
const createAppointment = async (pointData) => {
  const bodyRes = {
    outlet_id: pointData.outlet_id,
    status: 1,
    point_date: pointData.point_date,
    id_rooms: 1,
    start: pointData.start,
    pack_name: pointData.pack_name,
    ...{
      remarks: pointData.remarks ? pointData.remarks : 'E Appointment',
    },
    ...{
      type: pointData.remarks ? 'treatment' : 'consultation',
    },
    id_therapist: pointData.id_therapist,
    invoice_package_id: pointData.invoice_package_id,
  };

  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}/api/v1/appointments`,
    bodyRes,
    apiHeaderConfig(),
  );

  return response.data;
};

const editAppointment = async (pointData) => {
  const bodyRes = {
    id_appointment: pointData.id_appointment,
    point_date: pointData.point_date,
    start: pointData.start,
    id_therapist: parseInt(pointData.id_therapist, 10),
  };

  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}/api/v1/appointments/rescheduleAppointment`,
    bodyRes,
    apiHeaderConfig(),
  );

  return response.data;
};
const cancelAppointment = async (pointData) => {
  const response = await axiosApiInstance.delete(
    `${process.env.REACT_APP_API_HOST}/api/v1/appointments?id_appointment=${pointData.id_appointment}`,
    apiHeaderConfig(),
  );

  return response.data;
};

const getAllCancelAppointment = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/appointments${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const getAllAppointmentByUpcoming = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/appointments${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

// @Get All Appointment By History
const getAllAppointmentByHistory = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/appointments${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

// @Get Clinic Doctors
const getDoctors = async () => {
  const response = await axiosApiInstance.get(`${process.env.REACT_APP_API_HOST}${API_URL}doctors`);

  return response.data;
};

const getAvailableSchedule = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL}appointments/available-schedule${arg}`,
    apiHeaderConfig(),
  );
  return response.data;
};

// @Get Clinic Rooms
const getRooms = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${user?.data?.accessToken}`,
      'X-Instance-Id': clinic?.data?.dbName,
    },
  };

  const response = await axiosApiInstance.get(`${process.env.REACT_APP_API_HOST}${API_URL}rooms`, config);

  return response.data;
};

const appointmentService = {
  createAppointment,
  getAllAppointmentByUpcoming,
  getAllAppointmentByHistory,
  getDoctors,
  getRooms,
  cancelAppointment,
  getAllCancelAppointment,
  getAvailableSchedule,
  editAppointment,
};

export default appointmentService;
