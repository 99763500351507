/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import 'react-toastify/dist/ReactToastify.css';

import { notification } from 'antd';
import { onChildChanged, ref, remove } from 'firebase/database';
import { AnimatePresence } from 'framer-motion';
import Pusher from 'pusher-js';
import React, { Suspense, useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { SpinnerLoading } from '../components';
import StatusAppointment from '../components/atoms/StatusAppointment';
import CONSTANT from '../constant/index';
import { getActivity } from '../features/activity/activitySlice';
import { getClinicByHost } from '../features/clinic/clinicSlice';
import { database } from '../firebase';
/* import { fetchToken, onMessageListener } from '../firebase';
import { requestFirebaseNotificationPermission } from '../firebaseInit'; */
import routes from '../routes';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function PrivateRoute({ children, ...rest }) {
  const { user, isSuccess } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSuccess || user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              search: `?redirect_to="${location.pathname}"`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryColor = new URLSearchParams(location.search).get('color');
  const { user, isSuccess } = useSelector((state) => state.auth);
  /* const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  /!* fetchToken(setTokenFound); *!/

  requestFirebaseNotificationPermission()
    .then((firebaseToken) => {
      // eslint-disable-next-line no-console
      console.log(firebaseToken);
    })
    .catch((err) => err);
  onMessageListener().then((payload) => {
    setNotification({ title: payload.notification.title, body: payload.notification.body });
    setShow(true);
    console.log(payload);
  }).catch((err) => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({ title: 'Notification', body: 'This is a test notification' });
    setShow(true);
  }; */

  const [isOpenAppointmentAlert, setOpenAppointmentAlert] = useState(false);
  const [appointmentDetail, setAppointmentDetail] = useState(null);
  const [isLoadingHost, setIsloadingHost] = useState(true);
  useEffect(() => {
    const fetchHost = async () => {
      setIsloadingHost(true);
      await dispatch(getClinicByHost());
      await dispatch(getActivity());
      setIsloadingHost(false);
    };

    fetchHost();
  }, [dispatch]);

  useEffect(() => {
    // document.documentElement.setProperty('--bg-primary', 'red');
    // document.body.style.setProperty('--bg-primary', 'red');
    /* subscribe if under authenticated mode */
    /* subscribe to multi channel at this moment to appointment channel with various event */

    const pusher = new Pusher(process.env.REACT_APP_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
      encrypted: true,
    });
    if (localStorage.getItem('user')) {
      const parseUser = JSON.parse(localStorage.getItem('user'));
      for (let i = 0; i < CONSTANT.APPOINTMENT_STATUS.length - 1; i += 1) {
        const channel = pusher.subscribe(CONSTANT.APPOINTMENT_CHANNEL_NAME);
        const event = `${parseUser.data.email}:${CONSTANT.APPOINTMENT_STATUS[i]}`;
        channel.bind(event, (data) => {
          notification.open({
            duration: 0,
            message: data.message,
            description: data.description,
            onClick: () => {
              window.location.href = data.link;
            },
          });
        });
      }
    }
    return () => {
      pusher.unsubscribe(CONSTANT.APPOINTMENT_CHANNEL_NAME);
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (localStorage.getItem('user') && localStorage.getItem('clinic')) {
      const parseUser = JSON.parse(localStorage.getItem('user'));
      const parseClinic = JSON.parse(localStorage.getItem('clinic'));
      const query = ref(database, `update/appointment/${parseClinic.data.instance_id}/${parseUser.data.id}`);

      return onChildChanged(query, (snapshot) => {
        if (snapshot.exists()) {
          setAppointmentDetail(snapshot.val());
          console.log('snapshot.val()', snapshot.val());
          setOpenAppointmentAlert(true);
          toast.success('Your appointment has changes');
          remove(ref(database, 'update/appointment'));
        }
      });
    }
  }, [localStorage.getItem('user'), localStorage.getItem('clinic')]);

  useEffect(() => {
    const previewColor = `${queryColor}`.length === 6 ? `#${queryColor}` : '';

    if ((user || isSuccess) && location.pathname !== '/e-appointment' && previewColor) {
      window.location.href = `/e-appointment?color=${previewColor}`.replaceAll('#', '');
    }
    if (localStorage.getItem('clinic')) {
      const parseClinic = JSON.parse(localStorage.getItem('clinic'));

      if (previewColor) {
        localStorage.setItem(
          'clinic',
          JSON.stringify({
            ...parseClinic,
            data: {
              ...parseClinic.data,
              primaryColor: previewColor,
            },
          }),
        );
      }

      const style = document.createElement('style');

      const primaryColor = previewColor || parseClinic?.data?.primaryColor || '#8B69AE';

      console.log({
        previewColor,
        primaryColor: parseClinic?.data?.primaryColor,
        primaryColor2: primaryColor,
      });
      // focus:outline-primary

      style.type = 'text/css';
      style.innerHTML = `
        .bg-primary { background-color: ${primaryColor} !important; }
        .bg-\\[\\#8B6AAE\\] { background-color: ${primaryColor} !important; }
        .\\!bg-primary { background-color: ${primaryColor} !important; }
        .text-primary { color: ${primaryColor}; }
        .text-\\[\\#8B6AAE\\] { color: ${primaryColor} !important; }
        .hover\\:text-primary:hover { color: ${primaryColor}; }
        .border-primary { border-color: ${primaryColor} !important; }
        .border-primary\\/90 { border-color: ${primaryColor}90 !important; }
        .\\!border-primary { border-color: ${primaryColor} !important; }
        .focus\\:border-primary:focus { border-color: ${primaryColor} !important; }
        .focus\\:outline-primary:focus { outline-color: ${primaryColor} !important; }
        .before\\:bg-primary:before { background-color: ${primaryColor} !important; }
        .active\\:bg-\\[\\#8B69AE\\]\\/90:active { background-color: ${primaryColor} !important; }
        .focus\\:bg-\\[\\#8B69AE\\]:focus { background-color: ${primaryColor} !important; }
        .hover\\:bg-\\[\\#8B69AE\\]:hover { background-color: ${primaryColor} !important; }
        .hover\\:bg-primary:hover { background-color: ${primaryColor} !important; }
        .bg-\\[\\#46355759\\] { background-color: ${primaryColor}59 !important; }
        .bg-primary\\/20{background-color:${primaryColor}20}
         .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
          background: ${primaryColor} !important;
         }
         
         .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
          border-color: ${primaryColor} !important;
         }
         
         .ant-picker-today-btn {
          color: ${primaryColor} !important;
         }
      `;
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }, [localStorage.getItem('clinic'), isLoadingHost, location, user, isSuccess]);

  if (isLoadingHost) {
    return <SpinnerLoading />;
  }
  return (
    <div className="relative w-full bg-[#f5f5f7] max-w-screen-sm mx-auto">
      <ScrollToTop />
      <Suspense fallback={<SpinnerLoading />}>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.key}>
            {routes.map(({ path, isAuthenticated, Component }) =>
              isAuthenticated ? (
                <PrivateRoute key={path} exact path={path}>
                  <Component />
                </PrivateRoute>
              ) : (
                <Route key={path} exact path={path}>
                  <Component />
                </Route>
              ),
            )}
          </Switch>
        </AnimatePresence>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        src="https://kiwari.s3.ap-southeast-1.amazonaws.com/sound/outlook_reminder.mp3"
        id="audio"
        className="hidden"
      />
      <Drawer
        open={isOpenAppointmentAlert}
        onClose={() => {
          setOpenAppointmentAlert((prev) => !prev);
        }}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        <div className="py-2 px-6 text-center">
          <h3 className="font-body text-lg font-bold text-[#4F4F4F]">Appointment Notification</h3>
          <p className="font-body text-sm text-desc">Your appointment has changes: </p>
          {appointmentDetail ? (
            <div className="flex flex-col my-5">
              <div className="border-bs border-slate-100" />
              <div className="flex py-0.5">
                <span className="relative w-28 h-20 font-body font-medium  text-primary/75 tracking-wide flex flex-col items-center justify-center p-3 before:content-[''] before:h-[calc(100%_-_20%)] before:w-[1px] before:absolute before:top-0 before:bottom-0 before:right-0 before:bg-[#463557]/10 before:m-auto">
                  <span className="flex items-center justify-center text-3xl font-body tracking-normal text-primary">
                    <em className="not-italic  text-primary ml-1">
                      {appointmentDetail?.time || appointmentDetail?.start_time || '10:00'}
                    </em>
                  </span>
                </span>
                <div className="relative flex flex-col items-stretch justify-between text-left flex-1 p-3">
                  <p className="font-body text-xl font-normal text-desc">{appointmentDetail?.outlet?.outlet_name}</p>
                  <h6 className="font-body text-base font-normal text-primary">
                    {appointmentDetail?.come_date_formatted}
                  </h6>
                  <div className="flex">
                    <StatusAppointment statusAppointment={appointmentDetail?.status} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="flex mt-4">
            <button
              type="button"
              className="font-body flex-auto text-sm tracking-wider bg-white py-3 mr-2 px-6 rounded-lg text-accent shadow-sm border-none"
              onClick={() => {
                setOpenAppointmentAlert((prev) => !prev);
              }}
            >
              <b>Close</b>
            </button>
            <button
              type="button"
              className="font-body flex-auto text-sm tracking-wider bg-primary py-3 ml-2 px-6 rounded-lg text-white shadow-sm border-none"
              onClick={() => {
                window.location.href = '/my-e-appointment';
              }}
            >
              <b>Reload</b>
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default App;
