/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import usePasswordToggle from '../../hooks/usePasswordToggle';

function PasswordInput({
  label,
  fieldName,
  idEyeIcon,
  register,
  errors,
  placeholder,
  isRequired,
  maximLength,
  minimLength,
  isDisabled,
  ...props
}) {
  const [passwordInputType, toggleIcon] = usePasswordToggle(idEyeIcon);

  return (
    <label htmlFor={fieldName} className={`${!!isDisabled && 'opacity-50'}`}>
      <span
        className={`font-body text-xs font-normal text-black-gray block ${errors[fieldName] ? 'text-danger/50' : ''}`}
      >
        {label}
        {isRequired && <sup className="text-danger -top-[0.25em]"> *</sup>}
      </span>
      <div className="relative">
        <input
          id={fieldName}
          name={fieldName}
          type={passwordInputType}
          placeholder={placeholder}
          autoComplete="off"
          className={`w-full px-0 py-1 bg-transparent border-l-0 border-t-0 border-r-0 border-b-[1.5px] ring-0 outline-none focus:ring-0 border-[#D8D8D8] focus:border-primary ${
            errors[fieldName] ? '!border-danger' : ''
          }`}
          disabled={!!isDisabled}
          {...register(fieldName, {
            required: {
              value: isRequired,
              message: 'This is required',
            },
            maxLength: {
              value: maximLength,
              message: `Value must be maximum ${maximLength}`,
            },
            minLength: {
              value: minimLength,
              message: `Value must be minimum ${minimLength}`,
            },
          })}
          {...props}
        />
        {toggleIcon}
      </div>
      {errors[fieldName] && (
        <small id={`txt_error_${fieldName}`} className="text-danger text-tiny block mt-2">
          {errors[fieldName].message}
        </small>
      )}
    </label>
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string]),
  isRequired: PropTypes.oneOfType([PropTypes.bool]),
  maximLength: PropTypes.oneOfType([PropTypes.number]),
  minimLength: PropTypes.oneOfType([PropTypes.number]),
  isDisabled: PropTypes.oneOfType([PropTypes.bool]),
};

PasswordInput.defaultProps = {
  placeholder: '',
  isRequired: false,
  maximLength: 999999,
  minimLength: 1,
  isDisabled: false,
};

export default React.memo(PasswordInput);
