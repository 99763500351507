import React, { useRef, useState } from 'react';
import { HiOutlineSearch, HiOutlineX } from 'react-icons/hi';

function InputSearch({ onInput, onChange, ...rest }) {
  const [value, setValue] = useState('');
  const inputRefs = useRef();
  return (
    <div className="relative group">
      <span className="absolute h-full flex jsutify-center items-center ml-3">
        <HiOutlineSearch className="text-lg text-gray-400 group-hover:text-primary" />
      </span>
      <input
        ref={inputRefs}
        className="w-full box-border border-2 rounded-md !pl-8 !pr-8 px-4 py-2 font-body text-gray-700 placeholder:text-gray-400 focus:outline-primary"
        placeholder="Search something"
        onChange={onChange}
        onInput={(e) => {
          setValue(e.target.value);
          if (onInput) {
            onInput(e);
          }
        }}
        value={value}
        {...rest}
      />
      {value && (
        <button
          type="button"
          onClick={() => {
            setValue('');
            inputRefs?.current?.focus();
            if (onInput) {
              onInput('');
            }
          }}
          className="cursor-pointer absolute h-full top-0 right-3 flex jsutify-center items-center ml-3"
        >
          <HiOutlineX className="text-lg text-gray-400 group-hover:text-primary" />
        </button>
      )}
    </div>
  );
}

export default React.memo(InputSearch);
