import React from 'react';
import { HiOutlineCalendar, HiOutlineCog, HiOutlineGift, HiOutlineHeart } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

const menus = [
  {
    text: 'Appointment',
    icon: <HiOutlineCalendar className="w-6 h-6 mb-1 text-gray-500 group-hover:text-slate-50" />,
    path: '/e-appointment',
  },
  {
    text: 'Wishlist',
    icon: <HiOutlineHeart className="w-6 h-6 mb-1 text-gray-500 group-hover:text-slate-50" />,
    path: '/wishlist',
  },
  {
    text: 'Transaction',
    icon: <HiOutlineGift className="w-6 h-6 mb-1 text-gray-500 group-hover:text-slate-50" />,
    path: '/transaction',
  },
  {
    text: 'Account',
    icon: <HiOutlineCog className="w-6 h-6 mb-1 text-gray-500 group-hover:text-slate-50" />,
    path: '/profile/account',
  },
];
function BottomBar() {
  const history = useHistory();
  console.log({ history });
  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-[#f5f5f7] backdrop-blur border-t border-gray-200 ">
      <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium ">
        {menus.map((menu) => (
          <button
            type="button"
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-primary group"
            onClick={() => {
              history.push(`${menu.path}?from=${history.location.pathname}`);
            }}
          >
            {/* <HiOutlineCalendar className="w-6 h-6 mb-1 text-gray-500 group-hover:text-slate-50" /> */}
            {menu.icon}
            <span className="text-xs text-gray-500 group-hover:text-slate-50">{menu.text}</span>
          </button>
        ))}
        {/* <button */}
        {/*  type="button" */}
        {/*  className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" */}
        {/* > */}
        {/*  <HiOutlineGift className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" /> */}
        {/*  <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"> */}
        {/*    Transaction */}
        {/*  </span> */}
        {/* </button> */}
        {/* <button */}
        {/*  type="button" */}
        {/*  className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" */}
        {/* > */}
        {/*  <HiOutlineCog className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" /> */}
        {/*  <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"> */}
        {/*    Account */}
        {/*  </span> */}
        {/* </button> */}
      </div>
    </div>
  );
}

export default React.memo(BottomBar);
