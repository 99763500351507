/* import dateFormat from 'dateformat'; */
import { List } from 'antd';
import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';

function ItemPurchaseHistory({
  // eslint-disable-next-line camelcase
  inv_code,
  total,
  // eslint-disable-next-line camelcase
  inv_date,
  // eslint-disable-next-line camelcase
  expired_date,
  active,
  onclick,
  details,
  isLast = false,
}) {
  const [openItemsDrawer, setOpenItemsDrawer] = useState(false);
  const [itemTransaction, setItemTransaction] = useState([]);

  const toggleDrawer = () => {
    setOpenItemsDrawer((prevState) => !prevState);
  };

  const onClickItems = (itemParameter) => {
    setOpenItemsDrawer(true);
    setItemTransaction(itemParameter);
  };

  return (
    <div
      role="presentation"
      className={`${onclick ? 'cursor-pointer' : ''} relative block bg-white mb-4 w-full gap-4 cart-item ${
        active ? 'border-2 border-[#A988CC] border-solid' : ''
      } ${isLast ? '' : ''}`}
    >
      <div className="flex">
        <span className="block p-3 pr-0" />
        <div className="relative p-3 w-full">
          <div className="flex">
            <span className="w-32 text-xs font-medium">Code</span>
            <h3 className="font-display text-base font-medium text-dark/90">
              {/* eslint-disable-next-line camelcase */}
              {inv_code}
            </h3>
          </div>
          <div className="flex">
            <span className="w-32 text-xs font-medium">Total</span>
            <p
              style={{
                marginTop: '1px',
              }}
              className="font-body text-sm font-normal text-dark/60 pr-8"
            >
              {total}
            </p>
          </div>

          <div className="flex">
            <span className="w-32 text-xs font-medium">Invoice Date</span>
            <p
              style={{
                marginTop: '4px',
              }}
              className="font-body text-xs font-bold text-dark/60 pr-8"
            >
              {/* eslint-disable-next-line camelcase */}
              {inv_date}
            </p>
          </div>
          <div className="flex">
            <span className="w-32 text-xs font-medium">Expired Date</span>
            <p
              style={{
                marginTop: '4px',
              }}
              className="font-body text-xs font-bold text-dark/60 pr-8"
            >
              {/* eslint-disable-next-line camelcase */}
              {expired_date}
            </p>
          </div>
          <div className="flex">
            <span className="w-32 text-xs font-medium" />
            <p
              role="presentation"
              onClick={() => {
                onClickItems(details);
              }}
              className="cursor-pointer text-xs text-primary font-medium"
            >
              See Items
            </p>
          </div>
        </div>
      </div>
      <Drawer
        open={openItemsDrawer}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
        zIndex={100000}
      >
        <List
          className="wishlist_item_wrapper"
          dataSource={itemTransaction}
          renderItem={(itemRender) => (
            <List.Item key={itemRender.code}>
              <div className="relative p-3 w-full">
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Code</span>
                  <h3 className="font-display text-base font-medium text-dark/90">{itemRender.code}</h3>
                </div>
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Item</span>
                  <p
                    style={{
                      marginTop: '1px',
                    }}
                    className="font-body text-sm font-normal text-dark/60"
                  >
                    {itemRender.name}
                  </p>
                </div>
              </div>
            </List.Item>
          )}
        />
        {/* Inv Code
        Name
        Used
        Balance
        Therapist */}
      </Drawer>
    </div>
  );
}

ItemPurchaseHistory.propTypes = {};

export default React.memo(ItemPurchaseHistory);
