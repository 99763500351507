/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Redirect } from 'react-router-dom';

const Onboarding = React.lazy(() => import('./pages/Onboarding'));
const SignIn = React.lazy(() => import('./pages/Auth/SignIn'));
const SignUp = React.lazy(() => import('./pages/Auth/SignUp'));
const PatientNew = React.lazy(() => import('./pages/Auth/PatientNew'));
const PatientExisting = React.lazy(() => import('./pages/Auth/PatientExisting'));
const OtpPatientExisting = React.lazy(() => import('./pages/Auth/PatientExisting/OtpPatientExisting'));
const CheckPatientData = React.lazy(() => import('./pages/Auth/PatientExisting/CheckPatientData'));
const RegisterPatientData = React.lazy(() => import('./pages/Auth/PatientExisting/RegisterPatientData'));
const OtpVerification = React.lazy(() => import('./pages/Auth/OtpVerification'));
const Forgot = React.lazy(() => import('./pages/Auth/Forgot'));
const ForgotOtp = React.lazy(() => import('./pages/Auth/Forgot/Otp'));
const NewPassword = React.lazy(() => import('./pages/Auth/NewPassword'));
const Landing = React.lazy(() => import('./pages/Landing'));
const EAppointment = React.lazy(() => import('./pages/EAppointment'));
const CreateEAppointment = React.lazy(() => import('./pages/EAppointment/CreateEAppointment'));
const OutletConsultation = React.lazy(() => import('./pages/EAppointment/CreateEAppointment/OutletConsultation'));
const OutletTreatment = React.lazy(() => import('./pages/EAppointment/CreateEAppointment/OutletTreatment'));
const Consultant = React.lazy(() => import('./pages/EAppointment/CreateEAppointment/Consultant'));
const Treatment = React.lazy(() => import('./pages/EAppointment/CreateEAppointment/Treatment'));
const Room = React.lazy(() => import('./pages/EAppointment/CreateEAppointment/Room'));
const MyEAppointment = React.lazy(() => import('./pages/EAppointment/MyEAppoinment'));
const Products = React.lazy(() => import('./pages/Products'));
const Shipping = React.lazy(() => import('./pages/Shipping'));
const Address = React.lazy(() => import('./pages/Shipping/Address'));
const CreateAddress = React.lazy(() => import('./pages/Shipping/Address/Create'));
const PaymentSucceed = React.lazy(() => import('./pages/Shipping/Payment'));
const Transaction = React.lazy(() => import('./pages/Transaction'));
const ECheckin = React.lazy(() => import('./pages/ECheckin'));
const QrCode = React.lazy(() => import('./pages/QrCode'));
const Notification = React.lazy(() => import('./pages/Notification'));
const Wishlist = React.lazy(() => import('./pages/Wishlist'));
const Cart = React.lazy(() => import('./pages/Cart'));
const Personal = React.lazy(() => import('./pages/Profile/Personal'));
const Account = React.lazy(() => import('./pages/Profile/Account'));
const ChangePassword = React.lazy(() => import('./pages/Profile/Account/ChangePassword'));
const Search = React.lazy(() => import('./pages/Search'));
const Error404 = React.lazy(() => import('./pages/Error404'));
const Error401 = React.lazy(() => import('./pages/Error401'));
const Error500 = React.lazy(() => import('./pages/Error500'));
const TreatmentBalance = React.lazy(() => import('./pages/TreatmentBalance'));
const TreatmentTransaction = React.lazy(() => import('./pages/TreatmentTransaction'));
const PurchaseHistory = React.lazy(() => import('./pages/PurchaseHistory'));

const routes = [
  {
    path: '/',
    name: 'Onboarding',
    isAuthenticated: false,
    Component: Onboarding,
  },
  {
    path: '/signin',
    name: 'Masuk',
    isAuthenticated: false,
    Component: SignIn,
  },
  {
    path: '/signup',
    name: 'Daftar',
    isAuthenticated: false,
    Component: SignUp,
  },
  {
    path: '/new-patient',
    name: 'Daftar Pasien Baru',
    isAuthenticated: false,
    Component: PatientNew,
  },
  {
    path: '/existing-patient',
    name: 'Daftar Pasien Lama',
    isAuthenticated: false,
    Component: () => <Redirect to="/existing-patient/step-1" />,
  },
  {
    path: '/existing-patient/step-1',
    name: 'Daftar Pasien Lama',
    isAuthenticated: false,
    Component: PatientExisting,
  },
  {
    path: '/existing-patient/step-2',
    name: 'OTP Pasien Lama',
    isAuthenticated: false,
    Component: OtpPatientExisting,
  },
  {
    path: '/existing-patient/step-3',
    name: 'Cek Pasien Lama',
    isAuthenticated: false,
    Component: CheckPatientData,
  },
  {
    path: '/existing-patient/step-4',
    name: 'Daftarkan Data Pasien',
    isAuthenticated: false,
    Component: RegisterPatientData,
  },
  {
    path: '/otp',
    name: 'Verifikasi Akun',
    isAuthenticated: false,
    Component: OtpVerification,
  },
  {
    path: '/forgot-password',
    name: 'Lupa Kata Sandi',
    isAuthenticated: false,
    Component: Forgot,
  },
  {
    path: '/forgot-password-otp',
    name: 'Lupa Kata Sandi OTP',
    isAuthenticated: false,
    Component: ForgotOtp,
  },
  {
    path: '/create-new-password',
    name: 'Ubah Kata Sandi',
    isAuthenticated: false,
    Component: NewPassword,
  },
  {
    path: '/landing',
    name: 'Beranda',
    isAuthenticated: true,
    Component: Landing,
  },
  {
    path: '/e-appointment',
    name: 'e-Appointment',
    isAuthenticated: true,
    Component: EAppointment,
  },
  {
    path: '/e-appointment/create',
    name: 'Buat Janji Temu',
    isAuthenticated: true,
    Component: CreateEAppointment,
  },
  {
    path: '/e-appointment/outlet-consultation',
    name: 'Outlet',
    isAuthenticated: true,
    Component: OutletConsultation,
  },
  {
    path: '/e-appointment/outlet-treatment',
    name: 'Outlet',
    isAuthenticated: true,
    Component: OutletTreatment,
  },
  {
    path: '/e-appointment/consultant',
    name: 'Pilih Dokter',
    isAuthenticated: true,
    Component: Consultant,
  },
  {
    path: '/e-appointment/treatment',
    name: 'Pilih Perawatan',
    isAuthenticated: true,
    Component: Treatment,
  },
  {
    path: '/e-appointment/room',
    name: 'Pilih Ruangan',
    isAuthenticated: true,
    Component: Room,
  },
  {
    path: '/my-e-appointment',
    name: 'Pilih Dokter',
    isAuthenticated: true,
    Component: MyEAppointment,
  },
  {
    path: '/products',
    name: 'Produk',
    isAuthenticated: false,
    Component: Products,
  },
  {
    path: '/shipping',
    name: 'Pengiriman',
    isAuthenticated: false,
    Component: Shipping,
  },
  {
    path: '/shipping/address',
    name: 'Alamat',
    isAuthenticated: false,
    Component: Address,
  },
  {
    path: '/shipping/address/create',
    name: 'Tambah Alamat',
    isAuthenticated: false,
    Component: CreateAddress,
  },
  {
    path: '/shipping/payment-succeed',
    name: 'Pembayaran Berhasil',
    isAuthenticated: false,
    Component: PaymentSucceed,
  },
  {
    path: '/transaction',
    name: 'Transaksi',
    isAuthenticated: false,
    Component: Transaction,
  },
  {
    path: '/e-checkin',
    name: 'Tampilkan Kode',
    isAuthenticated: true,
    Component: ECheckin,
  },
  {
    path: '/qrcode',
    name: 'Tampilkan Kode',
    isAuthenticated: true,
    Component: QrCode,
  },
  {
    path: '/notification',
    name: 'Notifikasi',
    isAuthenticated: true,
    Component: Notification,
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    isAuthenticated: true,
    Component: Wishlist,
  },
  {
    path: '/cart',
    name: 'Keranjang',
    isAuthenticated: true,
    Component: Cart,
  },
  {
    path: '/profile/personal',
    name: 'Personal Data',
    isAuthenticated: true,
    Component: Personal,
  },
  {
    path: '/profile/account',
    name: 'Akun Data',
    isAuthenticated: true,
    Component: Account,
  },
  {
    path: '/profile/account',
    name: 'Akun Data',
    isAuthenticated: true,
    Component: Account,
  },
  {
    path: '/profile/password',
    name: 'Ganti Password',
    isAuthenticated: true,
    Component: ChangePassword,
  },
  {
    path: '/treatment-balance',
    name: 'Treatment Balance',
    isAuthenticated: true,
    Component: TreatmentBalance,
  },
  {
    path: '/treatment-transaction',
    name: 'Treatment History',
    isAuthenticated: true,
    Component: TreatmentTransaction,
  },
  {
    path: '/purchase-history',
    name: 'Purchase History',
    isAuthenticated: true,
    Component: PurchaseHistory,
  },
  {
    path: '/search',
    name: 'Pencarian',
    isAuthenticated: true,
    Component: Search,
  },
  {
    path: '/error-401',
    name: 'Halaman Tidak Bisa Diakses',
    isAuthenticated: false,
    Component: Error401,
  },
  {
    path: '/error-500',
    name: 'Terjadi Kesalahan Server',
    isAuthenticated: false,
    Component: Error500,
  },
  {
    path: '/error-404',
    name: 'Halaman Tidak Ditemukan',
    isAuthenticated: false,
    Component: Error404,
  },
  {
    path: '*',
    name: 'Halaman Tidak Ditemukan',
    isAuthenticated: false,
    Component: () => <Redirect to="/error-404" />,
  },
];

export default routes;
