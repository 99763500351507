import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const getGuidance = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/guidance/user/guide${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const API_URL_PRODUCT_CATEGORIES = '/api/v1/categories';
const updateGuidance = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_PRODUCT_CATEGORIES}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const getDefaultAddress = async () => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/get-default-address`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const deleteAddress = async (arg) => {
  const response = await axiosApiInstance.delete(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/remove-address/${arg.id}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const setDefaultAddress = async (arg) => {
  const response = await axiosApiInstance.patch(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/set-default-address/${arg.id}`,
    {},
    apiHeaderConfig(),
  );
  return response.data.data;
};

const getUserAddress = async () => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/get-address`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const showUserAddress = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/show-address/${arg}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const createUserAddress = async (arg) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/add-address`,
    arg,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const editUserAddress = async (arg) => {
  const response = await axiosApiInstance.patch(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/update-address/${arg.id}`,
    arg,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const getTreatmentTransaction = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/treatment-transaction${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data;
};

const getTreatmentBalance = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/treatment-balance${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data;
};

const getPurchaseHistory = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/purchase-history${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data;
};

const postChangePassword = async (arg) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}/api/v1/users/change-password-set`,
    arg,
    apiHeaderConfig(),
  );
  return response.data;
};

const UserService = {
  getGuidance,
  updateGuidance,
  getDefaultAddress,
  getUserAddress,
  showUserAddress,
  setDefaultAddress,
  deleteAddress,
  createUserAddress,
  editUserAddress,
  getTreatmentTransaction,
  getTreatmentBalance,
  getPurchaseHistory,
  postChangePassword,
};
export default UserService;
